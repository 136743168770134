@import-normalize;
@import "vars";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  font-family: Work Sans;
}
html,
body,
div,
h1 {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16pt;
  color: var(--color3);
  line-height: 1.5em;
}

body {
  // background: var(--backgroundColor);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > main {
    flex: 1 1 auto;
  }
}

h2 {
  // font-family: Roboto Slab;
  color: var(--color1);
  font-size: 20pt;
  font-weight: normal;
}
body>div>main {
  @media (orientation:portrait) {
    display: none !important;
  }
}
.ErrorMessege{ 
  background-color: white;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  
  line-height: 2em;
  color:var(--baseColor);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  background-image: url("./assets/willa/errorMessage.png");
  background-size: 100% 100%;
  img{
    width: 20vw;
    margin-bottom: 2em;
  }
  p{
    width: 52vw;
    font-size: 16px;
    line-height: 1.2em;
  }
  @media (orientation:portrait) {
   display: flex !important;
  }
  
  
}
.buttonLikeSection{
  position: absolute;
  bottom: 1em;
  right: 1em;
}