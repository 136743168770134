.box {
    background: white;
    color: var(--textColor);
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .inner{
      min-height: 100vh;
    }
}
