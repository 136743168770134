.buttonsRow {
    --gap: 0px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    & > * {
        margin: var(--gap) 0 0 var(--gap);
    }

    &.left {
        justify-content: left;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: right;
    }

    &.spaceBetween {
        justify-content: space-between;
    }
}
